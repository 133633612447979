<template>
  <v-row class="background-content" justify="center" align="center">
    <v-col cols="12" sm="12" md="8" lg="6" xl="6">
      <div>
        <v-main>
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-main>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
.background-content {
  min-height: 100vh;
  background-color: @colorMain;
  background-image: url("../../assets/img/background.png");
  background-size: 100%;
}
</style>
